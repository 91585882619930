import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"
import { Disqus } from 'gatsby-plugin-disqus'
import { MDXRenderer } from "gatsby-plugin-mdx"

export default function BlogPost({ data }) {
    const post = data.mdx
    const siteTitle = data.site.siteMetadata.title
    const image = "https://acornesque.net/images/" + post.frontmatter.image
     // ? post.frontmatter.image.childImageSharp.resize
    console.log(image)

    let disqusConfig = {
      url: "https://acornesque.net", //+ post.fields.slug,
      identifier: post.id,
      title: post.frontmatter.title,
    }
    return (
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.subheading || post.excerpt}
          image= {image}
          //pathname={post.location.pathname}
        />
        <div className="article">
          <div className="article-top">
              <h1 className="article-title">{post.frontmatter.title}</h1>
              <span className="article-subheading">{post.frontmatter.subheading}</span>
          </div>
          <MDXRenderer>{post.body}</MDXRenderer>
        </div>
        <Disqus config={disqusConfig} /> 
      </Layout>
    )
  }
  export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        subheading 
        image
      }
    }
  }
  `